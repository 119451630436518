import axios, { isCancel } from '../axios';

// catalogs
export const getLpuCatalogs = async (params, signal) => {
  try {
    const res = await axios.get(`v2/directories/lpu/`, { params, signal });
    return res;
  } catch (error) {
    if (isCancel(error)) {
      console.log('Request cancelled...');
      return;
    } else {
      throw error;
    }
  }
};

// green_channel
export const getGreenLpuFile = async () => {
  try {
    const res = await axios.get(`v2/directories/lpu/green_lpu/export/`, {
      responseType: 'blob',
    });
    return res;
  } catch (error) {
    if (isCancel(error)) {
      console.log('Request cancelled...');
      return;
    } else {
      throw error;
    }
  }
};

export const postGreenLpuFile = async data => {
  try {
    const res = await axios.postForm(
      `v2/directories/lpu/green_lpu/import/`,
      data,
    );

    return res;
  } catch (error) {
    if (isCancel(error)) {
      console.log('Request cancelled...');
      return;
    } else {
      throw error;
    }
  }
};

export const getCitiesCatalogs = async (params, signal) => {
  try {
    const res = await axios.get(`v2/directories/cities/`, { params, signal });
    return res;
  } catch (error) {
    if (isCancel(error)) {
      console.log('Request cancelled...');
      return;
    } else {
      throw error;
    }
  }
};

export const getNormativeActCatalogs = async (params, signal) => {
  try {
    const res = await axios.get(`v2/normative_act_rows/`, {
      params,
      signal,
    });
    return res;
  } catch (error) {
    if (isCancel(error)) {
      console.log('Request cancelled...');
      return;
    } else {
      throw error;
    }
  }
};

export const getNormativeActFile = async () => {
  try {
    const res = await axios.get(`v2/normative_act_rows/export/`, {
      responseType: 'blob',
    });
    return res;
  } catch (error) {
    if (isCancel(error)) {
      console.log('Request cancelled...');
      return;
    } else {
      throw error;
    }
  }
};

export const postNormativeActFile = async data => {
  try {
    const res = await axios.postForm(
      `v2/normative_act_rows/update_from_xlsx/`,
      {
        excel_file: data,
      },
    );

    return res;
  } catch (error) {
    if (isCancel(error)) {
      console.log('Request cancelled...');
      return;
    } else {
      throw error;
    }
  }
};

export const getNaAnalysisCatalogs = async (params, signal) => {
  try {
    const res = await axios.get(`v2/directories/normative_acts_analysis/`, {
      params,
      signal,
    });
    return res;
  } catch (error) {
    if (isCancel(error)) {
      console.log('Request cancelled...');
      return;
    } else {
      throw error;
    }
  }
};

export const getNaAnalysisFile = async () => {
  try {
    const res = await axios.get(`v2/normative_acts_analysis/export/`, {
      responseType: 'blob',
    });
    return res;
  } catch (error) {
    if (isCancel(error)) {
      console.log('Request cancelled...');
      return;
    } else {
      throw error;
    }
  }
};

// Groups
export const getGroups = async () => {
  try {
    const res = await axios.get('v2/directories/groups/');
    return res;
  } catch (error) {
    throw error;
  }
};

// org. mappings
export const getMappings = async signal => {
  try {
    const res = await axios.get('v2/directories/mappings/', { signal });
    return res;
  } catch (error) {
    throw error;
  }
};

// section names (system rules)
export const getSystemRulesSections = async () => {
  try {
    const res = await axios.get('v2/directories/sections_of_system_rules/');
    return res;
  } catch (error) {
    throw error;
  }
};

// formulations (system rules)
export const getMedExpertsFormulations = async () => {
  try {
    const res = await axios.get('v2/directories/formulations_of_med_experts/');
    return res;
  } catch (error) {
    throw error;
  }
};

// comments (system rules)
export const getRulesComments = async () => {
  try {
    const res = await axios.get('v2/directories/comments_of_system_rules/');
    return res;
  } catch (error) {
    throw error;
  }
};

// tasks
// product_type
export const getProductType = async params => {
  try {
    const res = await axios.get('v2/directories/product_types/', { params });
    return res;
  } catch (error) {
    throw error;
  }
};

// services
export const getAllServices = async params => {
  try {
    const res = await axios.get('v2/directories/services/', { params });
    return res;
  } catch (error) {
    throw error;
  }
};

// diagnoses
export const getAllDiagnoses = async params => {
  try {
    const res = await axios.get('v2/directories/diagnoses/', { params });
    return res;
  } catch (error) {
    throw error;
  }
};

// status
export const getAllStatuses = async () => {
  try {
    const res = await axios.get('v2/directories/task_statuses/');
    return res;
  } catch (error) {
    throw error;
  }
};

// insurers
export const getAllInsurers = async params => {
  try {
    const res = await axios.get('v2/directories/insurers/', { params });
    return res;
  } catch (error) {
    throw error;
  }
};

// unloading (cloud)
export const getUnloading = async ({
  orgId,
  startDate,
  endDate,
  expertiseOnly,
  fullInformation,
}) => {
  try {
    const res = await axios.get(`v2/unloading/${startDate}/${endDate}/`, {
      params: {
        for_expertise_only: expertiseOnly,
        full_information: fullInformation,
        org_id: orgId,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getLastUnloadingInfo = async signal => {
  try {
    const res = await axios.get(`v2/get_cloud_unload`, {
      signal,
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getLastUnloadingFile = async () => {
  try {
    const res = await axios.get(`v2/media/cloud_download/`, {
      responseType: 'blob',
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getUnloads = async () => {
  try {
    const res = await axios.get(`v2/directories/unloads/`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getInstructionFile = async () => {
  try {
    const res = await axios.get(`v2/media/instruction_file/`, {
      responseType: 'blob',
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export const getCaseTypes = async () => {
  try {
    const res = await axios.get(`v2/cases/case_types/list/`);

    return res;
  } catch (error) {
    throw error;
  }
};

export const getMemasProfiles = async () => {
  try {
    const res = await axios.get(`v2/directories/memas_profiles/`);

    return res;
  } catch (error) {
    throw error;
  }
};
