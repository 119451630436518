import { useRef, useState, useEffect } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import CustomCaret from './CustomCaret';
import CustomOptions from './CustomOptions';
import ValueContainer from './ValueContainer';
import MultiValue from './MultiValue';
import { sortOptions } from '../../utils';
import './CustomSelect.css';

const initialComponents = {
  DropdownIndicator: CustomCaret,
  Option: CustomOptions,
  ValueContainer,
  MultiValue,
  MultiValueRemove: () => null,
};

// у опции "Выбрать все", если такая есть, value всегда должно быть '*'
export default function CustomSelect({
  value,
  options,
  onChange,
  isMulti = false,
  id,
  name,
  getOptionLabel,
  getOptionValue,
  allowSelectAll = false,
  optionKey = {},
  isLoading = false,
  onFocus,
  placeholder = null,
  components = {},
  addClassName,
  ...props
}) {
  const [controlledOptions, setControlledOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const valueRef = useRef([]);
  const containerRef = useRef(null);
  const isFirstRenderOptionsRef = useRef(true);

  useEffect(() => {
    if (!isMulti) {
      setControlledOptions(options);
    } else {
      setControlledOptions(prev => {
        if (
          prev.length === options.length &&
          !isFirstRenderOptionsRef.current
        ) {
          return prev;
        } else {
          const sortedOptions = sortOptions(options, value, optionKey.value);
          return sortedOptions;
        }
      });
    }
  }, [isMulti, optionKey.value, options, value, name]);

  valueRef.current = value;

  const selectAllOption = {
    label: 'Выбрать все',
    value: '*',
  };

  const isSelectAllSelected = () =>
    valueRef.current.length === controlledOptions.length;

  const isSelectAllOptions = () =>
    valueRef.current.length ===
    controlledOptions.filter(
      el => el[optionKey.value] !== selectAllOption.value,
    ).length -
      1;

  const handleInputChange = (value, { action }) => {
    if (action !== 'set-value') {
      setInputValue(value);

      return value;
    }

    return inputValue;
  };

  const filterOptions = (option, input) => {
    const label = option?.label;
    if (!label) {
      return;
    }
    const newInput = input.trim().toLowerCase();
    return option.label.toLowerCase().includes(newInput);
  };

  return (
    <Select
      // menuIsOpen
      inputValue={inputValue}
      onInputChange={handleInputChange}
      isLoading={isLoading}
      id={id}
      options={controlledOptions}
      name={name}
      className={'searchable-select' + (addClassName ? ` ${addClassName}` : '')}
      classNamePrefix="searchable-select"
      placeholder={placeholder}
      loadingMessage={() => 'Загружаю...'}
      noOptionsMessage={() => 'Не найдено'}
      blurInputOnSelect={false}
      closeMenuOnSelect={!isMulti}
      allowSelectAll={allowSelectAll}
      hideSelectedOptions={false}
      isMulti={isMulti}
      filterOption={filterOptions}
      styles={{
        valueContainer: (base, state) => ({
          ...base,
          flexWrap: 'nowrap',
        }),
      }}
      components={{
        ...initialComponents,
        ...components,
      }}
      value={value}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      optionKey={optionKey}
      onFocus={onFocus}
      onChange={(selected, event) => {
        isFirstRenderOptionsRef.current = false;

        const { action, option, removedValue } = event;

        if (allowSelectAll) {
          if (
            (action === 'select-option' &&
              option[optionKey.value] === selectAllOption.value) ||
            (action === 'select-option' && isSelectAllOptions())
          ) {
            onChange(controlledOptions, event);
          } else if (
            (action === 'deselect-option' &&
              option[optionKey.value] === selectAllOption.value) ||
            (action === 'remove-value' &&
              removedValue[optionKey.value] === selectAllOption.value) ||
            action === 'clear'
          ) {
            onChange([], event);
          } else if (action === 'deselect-option' && isSelectAllSelected()) {
            onChange(
              controlledOptions.filter(
                el =>
                  el[optionKey.value] !== option[optionKey.value] &&
                  el[optionKey.value] !== selectAllOption.value,
              ),
              event,
            );
          } else if (action === 'remove-value' && isSelectAllSelected()) {
            onChange(
              controlledOptions.filter(
                el =>
                  el[optionKey.value] !== removedValue[optionKey.value] &&
                  el[optionKey.value] !== selectAllOption.value,
              ),
              event,
            );
          } else {
            onChange(selected || [], event);
          }
        } else {
          onChange(selected, event);
        }
      }}
      containerRef={containerRef}
      captureMenuScroll={false}
      onMenuOpen={() => {
        if (!isMulti) {
          return null;
        } else {
          const sortedOptions = sortOptions(options, value, optionKey.value);

          setControlledOptions(sortedOptions);
        }
      }}
      {...props}
    />
  );
}

CustomSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  optionKey: PropTypes.object,
  isLoading: PropTypes.bool,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  addClassName: PropTypes.string,
};
